import Vue from "vue";
import VueGtag from "vue-gtag";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

// See results at
// https://analytics.google.com/analytics/web/#/p267791356/reports/dashboard?params=_u..nav%3Ddefault&r=lifecycle-engagement-overview
Vue.use(VueGtag, {
  config: { id: "G-DGW8ZBHTCK" }
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: h => h(App)
}).$mount("#app");
